<template>
  <div>
    <div class="form-entry-container">
      <div class="form-entry-label">Form Templates</div>
      <div class="form-entry-input">
        <div class="forms">
          <kendo-datasource
            :ref="'formDataSource'"
            :data="formDataSource.data">
          </kendo-datasource>
          <div>
            <h3>In this option a power user will be able to Add/Edit/Delete Form Templates which will be used in all projects</h3>
            <button class="k-button" @click="addForm">Add Form Template</button>
            <kendo-grid
            :id="'selectedFormGrid'"
            :ref="'selectedFormGrid'"
            :data-source-ref="'formDataSource'"
            :scrollable="scrollable"
            :selectable="selectable"
            :columns="columns"
            :editable="editable"
            style="width: 800px;">
          </kendo-grid>
          </div>
        </div>
      </div>
    </div>
    <kendo-window :ref="'formAddWindow'"
                  :width="windowWidth"
                  :height="windowHeight"
                  :title="windowTitle"
                  :visible="windowVisible"
                  :modal="true"
                  :top="'0px'">
      <div v-if="addFormTemplateWizardScreen === 1">
        <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;">
          <div style="width: 50%;">
            <FormEntryContainer title="How to start" type="dropdown" :datasource="formTemplateTypeDataSource" :valuefield="'FormTemplateTypeId'" :textfield="'FormTemplateTypeName'" @change="onHowtoStart" labeltype="none"></FormEntryContainer>
            <div style="width: 350px;"><FormEntryContainer title="Search keywords" type="multiselect" autowidth=true size="small" :datasource="keywordDataSource" textfield="KeywordName" labeltype="none"></FormEntryContainer></div>
            <FormEntryContainer title="Other keywords (comma separated)" type="textarea" text="Study Characteristics" cols="80" rows="5" labeltype="none"></FormEntryContainer>
            <FormEntryContainer v-if="selectedDropdownIndex == 2" title="Available Form Templates" :datasource="formTemplatesDataSource" type="treelist" labeltype="none"></FormEntryContainer>
            <FormEntryContainer v-if="selectedDropdownIndex == 3" title="Available Project Forms" :datasource="projectTemplatesDataSource" type="treelist" labeltype="none"></FormEntryContainer>
          </div>
          <div style="width: 50%;">
            <FormEntryContainer title="Template description" type="textarea" text="Study Characteristics" cols="80" rows="5" labeltype="none"></FormEntryContainer>
            <FormEntryContainer title="Template Keywords" type="formKeywordReadOnly" labeltype="none"></FormEntryContainer>
          </div>
        </div>
        <SurveyEntryContainer title="Template Questions" type="surveyViewer" labeltype="none"></SurveyEntryContainer>
        <div style="display:flex; flex-direction: row; justify-content: flex-end;">
          <button class="k-button" @click="onSelectAndContinue">{{ addFormTemplateButtonText }}</button>
          <button class="k-button" @click="onCancelAddWindow">Cancel</button>
        </div>
      </div>
      <div v-if="addFormTemplateWizardScreen === 2">
        <FormEntryContainer title="Template description" type="textarea" text="Study Characteristics" cols="80" rows="5"></FormEntryContainer>
        <FormEntryContainer title="Template Keywords" type="formKeywordReadOnly"></FormEntryContainer>
        <SurveyEntryContainer title="Template Questions" type="surveyEditor"></SurveyEntryContainer>
        <div style="display:flex; flex-direction: row; justify-content: flex-end;">
          <button class="k-button" @click="onSaveAndExitAddWindow">Save and Exit</button>
          <button class="k-button" @click="onCancelAddWindowWizardScreen2">Cancel</button>
        </div>
      </div>
    </kendo-window>
    <kendo-window :ref="'formEditWindow'"
                  :width="windowWidth"
                  :height="windowHeight"
                  :title="windowTitle"
                  :position="windowPosition"
                  :visible="windowVisible"
                  :modal="true"
                  :top="'0px'">
      <FormEntryContainer title="Template Name" type="text" readonly="true" text="Study Characteristics" cols="80" rows="5" labeltype="none"></FormEntryContainer>
      <FormEntryContainer title="Template Description" type="textarea" text="Study Characteristics" cols="80" rows="5" labeltype="none"></FormEntryContainer>
      <FormEntryContainer title="Template Keywords" type="formKeywordEdit" labeltype="none"></FormEntryContainer>
      <SurveyEntryContainer title="Template Questions" type="surveyEditor" labeltype="none"></SurveyEntryContainer>
      <div style="display:flex; flex-direction: row; justify-content: flex-end;">
        <button class="k-button">Save Form Template</button>
        <button class="k-button" @click="onCancelEditWindow">Cancel</button>
      </div>
    </kendo-window>
    <kendo-window :ref="'formRemoveWindow'"
                  :width="windowWidth"
                  :height="windowHeight"
                  :title="windowTitle"
                  :visible="windowVisible"
                  :modal="true"
                  :top="'0px'">
      <div>
        <FormEntryContainer title="Template Name" type="text" readonly="true" text="Study Characteristics" cols="80" rows="5" labeltype="none"></FormEntryContainer>
        <FormEntryContainer title="Template Description" type="textarea" readonly="true" text="Study Characteristics Description" cols="80" rows="5" labeltype="none"></FormEntryContainer>
        <SurveyEntryContainer title="Template Questions" type="surveyViewer" labeltype="none"></SurveyEntryContainer>
        Are you sure you want to remove this Template?
      </div>
      <div style="display:flex; flex-direction: row; justify-content: flex-end;">
        <button class="k-button">Remove</button>
        <button class="k-button">Cancel</button>
      </div>
    </kendo-window>
  </div>
</template>

<script>
import FormEntryContainer from '@/containers/FormEntryContainer'
import SurveyEntryContainer from '@/containers/SurveyEntryContainerV2'
import FormDataSource from '@/assets/data/Form.json'
import KeywordDataSource from '@/assets/data/Keyword.json'

export default {
  name: 'admin-form-templates',
  components: {
    FormEntryContainer,
    SurveyEntryContainer
  },
  data () {
    return {
      selectable: true,
      sortable: true,
      pageable: false,
      groupable: false,
      scrollable: false,
      columns: this.generateColumns(),
      editable: {
        mode: 'popup',
        window: {
          animation: false,
          open: this.editForm
        }
      },
      selectedDropdownIndex: 0,
      formDataSource: FormDataSource,
      keywordDataSource: KeywordDataSource,
      addFormTemplateWizardScreen: 1,
      addFormTemplateButtonText: 'Save and Exit',
      windowTitle: '',
      windowWidth: '1550px',
      windowHeight: '700px',
      windowPosition: { left: 0 },
      windowVisible: false,
      formTemplateTypeDataSource: [
        { FormTemplateTypeId: 1, FormTemplateTypeName: 'From Scratch' },
        { FormTemplateTypeId: 2, FormTemplateTypeName: 'From an existing template' },
        { FormTemplateTypeId: 3, FormTemplateTypeName: 'From an existing project form' }
      ],
      formTemplatesDataSource: {
        data: [
          { NodeId: 1, NodeName: 'Template group 1' },
          { NodeId: 2, NodeName: 'Template 1', ReportsTo: 1 },
          { NodeId: 3, NodeName: 'Template 2', ReportsTo: 1 },
          { NodeId: 4, NodeName: 'Template 3', ReportsTo: 1 },
          { NodeId: 5, NodeName: 'Template group 2' },
          { NodeId: 6, NodeName: 'Template 1', ReportsTo: 5 },
          { NodeId: 7, NodeName: 'template 2', ReportsTo: 5 }
        ],
        schema: {
          model: {
            id: 'NodeId',
            parentId: 'ReportsTo',
            expanded: true,
            batch: true,
            fields: {
              NodeId: { type: 'number', editable: false, nullable: false },
              NodeName: { validation: { required: true } },
              ReportsTo: { nullable: true, type: 'number' }
            }
          }
        },
        columns: [
          { field: 'NodeName', title: ' ', width: 100, expandable: true }
        ]
      },
      projectTemplatesDataSource: {
        data: [
          { NodeId: 1, NodeName: 'Project 1' },
          { NodeId: 2, NodeName: 'Form 1', ReportsTo: 1 },
          { NodeId: 3, NodeName: 'Form 2', ReportsTo: 1 },
          { NodeId: 4, NodeName: 'Form 3', ReportsTo: 1 },
          { NodeId: 5, NodeName: 'Project 2' },
          { NodeId: 6, NodeName: 'Form 1', ReportsTo: 5 },
          { NodeId: 7, NodeName: 'Form 2', ReportsTo: 5 }
        ],
        schema: {
          model: {
            id: 'NodeId',
            parentId: 'ReportsTo',
            expanded: true,
            batch: true,
            fields: {
              NodeId: { type: 'number', editable: false, nullable: false },
              NodeName: { validation: { required: true } },
              ReportsTo: { nullable: true, type: 'number' }
            }
          }
        },
        columns: [
          { field: 'NodeName', title: ' ', width: 100, expandable: true }
        ]
      }
    }
  },
  methods: {
    generateColumns: function () {
      var columnsObject = []
      columnsObject.push({ field: 'FormName', title: 'Template', width: '140px' })
      columnsObject.push(
        {
          command: [
            // { name: 'Edit', className: 'k-state-disabled', click: this.editForm, width: '40px' },
            { name: 'Edit', attributes: { class: 'k-button' }, click: this.editForm, width: '40px' },
            { name: 'Remove', attributes: { class: 'k-button' }, click: this.removeForm, width: '40px' }
          ]
        }
      )
      return columnsObject
    },
    addForm: function (e) {
      var windowWidget = this.$refs.formAddWindow.kendoWidget()
      this.windowTitle = 'Add Template'
      this.windowWidth = '1550px'
      this.windowHeight = '700px'
      this.windowVisible = true
      this.windowPosition = { top: 0, left: 40 }
      windowWidget.close()
      windowWidget.open().center()
    },
    editForm: function (e) {
      var windowWidget = this.$refs.formEditWindow.kendoWidget()
      this.windowTitle = 'Edit Form Template'
      this.windowWidth = '1550px'
      this.windowHeight = '700px'
      this.windowVisible = true
      windowWidget.close()
      windowWidget.open().center()
    },
    removeForm: function (e) {
      var windowWidget = this.$refs.formRemoveWindow.kendoWidget()
      this.windowTitle = 'Remove Form'
      this.windowWidth = '1550px'
      this.windowHeight = '700px'
      this.windowVisible = true
      windowWidget.close()
      windowWidget.open().center()
    },
    onHowtoStart: function (e) {
      console.log(e.sender.selectedIndex)
      this.selectedDropdownIndex = e.sender.selectedIndex
      switch (this.selectedDropdownIndex) {
        case (1):
          this.addFormTemplateButtonText = 'Save and Exit'
          break
        case (2):
          this.addFormTemplateButtonText = 'Select and Edit Template'
          break
        case (3):
          this.addFormTemplateButtonText = 'Select and Edit Project Form'
          break
      }
    },
    onSelectAndContinue: function () {
      this.addFormTemplateWizardScreen = 2
    },
    onCancelEditWindow: function () {
      var windowWidget = this.$refs.formEditWindow.kendoWidget()
      windowWidget.close()
    },
    onSaveAndExitAddWindow: function () {
      this.selectedDropdownIndex = 0
      this.addFormTemplateWizardScreen = 1
      var windowWidget = this.$refs.formAddWindow.kendoWidget()
      windowWidget.close()
    },
    onCancelAddWindow: function () {
      this.selectedDropdownIndex = 0
      this.addFormTemplateWizardScreen = 1
      var windowWidget = this.$refs.formAddWindow.kendoWidget()
      windowWidget.close()
    },
    onCancelAddWindowWizardScreen2: function () {
      this.selectedDropdownIndex = 0
      this.addFormTemplateWizardScreen = 1
    }
  }
}
</script>
